<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          <!-- {{ userData.firstname }} = {{ userData.lastname }} = {{ userData._id }} -->
          {{ (userData && userData.firstname || "") + " " + (userData && userData.lastname || "") }}
        </p>
        <span class="user-status">
          {{ (userData && userData.role && userData.role.nom) || "" }}
          {{ (userData && userData.role && userData.role.code == 'agent_ddtca' && userData.associated_department && ' - ' + (userData.associated_department || "") ) || "" }}

          {{ etablissementDetails && ' : ' + etablissementDetails.nomEtablissement || "" }}
          {{ commissariatDetails && ' : ' + commissariatDetails.nom || "" }}
        </span>
        <span class="small"> </span>
      </div>
      
      <b-avatar
        size="40"
        :src="userData && userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData || !userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>
    <!-- <b-dropdown-item
      :to="{ name: 'account-settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Paramètres</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'manuel-utilisation' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="BookIcon" class="mr-50" />
      <span>Manuel d'utilisation</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Déconnexion</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue';
import { initialAbility } from '@/libs/acl/config';
import { avatarText } from '@core/utils/filter'
import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from "@/services/utils/utils.service";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: localstorageService.getUserData(),
      avatarText,
      etablissementDetails: null,
      commissariatDetails: null,
    };
  },
  mounted() {
    // console.log('---------- ::: ------', userData)
    const {
      etablissementDetails,
      commissariatDetails,
      userData,
    } = utilsService.currentUserUtils()
    if(etablissementDetails){
      this.etablissementDetails = etablissementDetails
      // console.log(' ⭕️⭕️',)
    }
    if(userData && (userData.commissariatAssociated || userData.nom) ){
      this.commissariatDetails = userData.commissariatAssociated || userData
      // console.log(' ⭕️⭕️⭕️', commissariatDetails)
    }
  },
  methods: {
    // async logout() {
    //   await this.$store.dispatch('auth/logout', {}).then(res=>{
    //      this.$ability.update(initialAbility);

    //   // Redirect to login page
    //   this.$router.replace({ name: 'home-public' });
    //   });
    // },
  },
};
</script>
